<template>
  <div class="pageHolder">
    <div
      :class="[showDetails ? 'tabItemSelected': 'tabItem']"
      @click="showTab('Details')"
    >Details</div>
    <div class="tabHolder">
      <v-container
        v-if="showDetails"
        fill-height
        fluid
        grid-list-xl
        class="tabContent">
        <v-layout
          justify-center
          wrap
        >
          <v-flex
            xs12
            md8
          >
            <material-card
              color="green"
              title="Reset Password"
              text="You can reset your password here."
            >
              <v-form @submit.prevent>
                <v-container py-0>
                  <v-layout wrap>
                    <div
                      class="appSectionContainer"
                      style="width:100%"
                    >
                      <div class="appSectionBody">
                        <PasswordChangeComponent
                          :username="username"
                          class="pwcBody"
                        />
                      </div>
                    </div>
                  </v-layout>
                </v-container>
              </v-form>
            </material-card>
            <material-card
              v-if="companies.length > 1"
              color="green"
              title="Companies"
              text="You can manage which Company is selected by default when logging into the portal."
            >
              <v-form @submit.prevent>
                <v-container py-0>
                  <v-layout wrap>
                    <div
                      class="appSectionContainer"
                      style="width:100%"
                    >
                      <div class="appSectionBody">
                        <ManageDefaultCustomersComponent />
                      </div>
                    </div>
                  </v-layout>
                </v-container>
              </v-form>
            </material-card>
          </v-flex>
          <v-flex
            xs12
            md4
          >
            <material-card class="v-card-profile">
              <v-avatar
                slot="offset"
                class="mx-auto d-block"
                size="130"
              >
                <img
                  :src="avatarIcon"
                >
              </v-avatar>
              <v-card-text class="text-xs-center">
                <h4 class="card-title font-weight-light">{{ fullName }}</h4>
                <h4 class="category text-gray font-weight-light mb-3">{{ username }}</h4>
                <p
                  v-if="username != emailAddress"
                  class="card-description font-weight-light"
                >{{ emailAddress }}
                </p>
              </v-card-text>
            </material-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container
        v-if="showTheme"
        fill-height
        fluid
        grid-list-xl
        class="tabContent">
        <v-layout
          justify-left
          wrap
        >
          <v-flex
            xs12
            md12
          >
            <material-card
              color="green"
              title="Grid Colour Theme"
              text="Set your grid colour prefrences."
            >
              <v-form @submit.prevent>
                <v-container py-0>
                  <v-layout wrap>
                    <div
                      class="appSectionContainer"
                      style="width:100%"
                    >
                      <div class="appSectionBody">
                        <ThemeComponent :userid="userId" />
                      </div>
                    </div>
                  </v-layout>
                </v-container>
              </v-form>
            </material-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import PasswordChangeComponent from '../components/passwordChange/passwordChangeComponent'
import ManageDefaultCustomersComponent from '../components/customers/manageDefaultCustomersComponent'
import ThemeComponent from '../components/profile/themeComponent'
import { usersMixin } from '../mixins/users'
import { DataEventBus } from '../events/dataEvents'
export default {
  name: 'UserProfile',
  metaInfo: {
    title: 'iQuote - My Account'
  },
  components: {
    PasswordChangeComponent,
    ManageDefaultCustomersComponent,
    ThemeComponent
  },
  mixins: [
    usersMixin
  ],
  data () {
    return {
      userId: 0,
      fullName: '',
      emailAddress: '',
      avatar: '',
      username: '',
      companies: [],
      showDetails: true,
      showTheme: false
    }
  },
  computed: {
    customers () {
      return this.$store.getters.customers
    },
    maintelTesting () {
      return this.$store.getters.maintelTesting
    },
    avatarIcon () {
      return require(`../assets/icons/Person.png`)
    }
  },
  mounted () {
    this.setUser()
    DataEventBus.$emit('still-active')
  },
  methods: {
    setUser: async function () {
      this.username = this.$store.getters.username
      var usr = await this.getUser(this.username)
      if (usr.id === 0) {
        usr = await this.getUser(this.$store.getters.userEmail)
      }
      this.userId = usr.id
      this.fullName = usr.firstName + ' ' + usr.lastName
      this.emailAddress = usr.emailAddress
      this.avatar = usr.avatar
      var customers = this.$store.getters.customers
      customers.forEach(customer => {
        if (this.companies.filter(company => company === customer.companyId).length === 0) {
          this.companies.push(customer.companyId)
        }
      })
    },
    showTab (tab) {
      if (tab === 'Details') {
        this.showDetails = true
        this.showTheme = false
      } else if (tab === 'Theme') {
        this.showTheme = true
        this.showDetails = false
      }
    }
  }
}
</script>
<style scoped>
  .pageHolder {
    display: block;
    margin: 10px;
  }
  .tabContent {
    border: 1px solid #66bb6a;
    border-radius: 0 5px 5px 5px;
    margin-bottom: 50px;
  }
  .tabHolder {
    width: 100px;
    display: inline;
  }
  .tabItem {
    display: inline-block;
    cursor: pointer;
    background: linear-gradient(60deg, #66bb6a, #43a047) !important;
    padding: 3px 7px 0 7px;
    border-radius: 5px 5px 0 0;
    color: #eee;
  }
  .tabItemSelected {
    display: inline-block;
    cursor: pointer;
    background: linear-gradient(60deg, #66bb6a, #43a047) !important;
    padding: 3px 7px 0 7px;
    border-radius: 5px 5px 0 0;
    color: white;
    font-weight: bold;
  }
  #pwdOldPassword, #pwdChangePasswordNew, #pwdChangePasswordConfirm {
    width: calc(100% + 22px);
    height: 26px;
    padding: 2px 5px;
    border: none;
    border-bottom: solid 1px #666;
  }
  img {
    padding: 4px;
    width: 70%;
    height: 70%;
  }
</style>
